#menu {
  max-width: 850px;
  width: 100%;
  padding: 35px;
  text-align: right;
  margin: 20px auto 20px auto;
  z-index: 1;
  position: relative;
}

#menu a#logo, .sitemap a.logo {
  float: left;
  font-size: 27px;
  opacity: 1;
  margin-top: 4px;
  padding-top: 1px;
  margin-left: 0;
  width: 100px;
  border: none;
}

#menu a {
  color: #444;
  opacity: 1;
  font-size: 16px;
  margin-left: 50px;
  font-weight: 800;
  transition: 0.25s ease-in-out;
  border-bottom: 2px solid transparent;
  -moz-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
}

.App {
  background-color: #f3f3f3;
  /* height:100vh;
  width: 100vw; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body{
  background-color: #f3f3f3 !important;
  padding: 25px;
  font-family: 'Lexend Deca';
}

.App-header {
  background-color: #f3f3f3;
  /* min-height: 100vh; */
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 65vmin;
}

.App-slide {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid green;
}

.privacy {
  background-color: #f3f3f3;
  padding: 64px;
}
